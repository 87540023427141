<template>
  <div class="form-field-group">
    <h3 class="form-title">
      Profile Details <i class="fas fa-pencil-alt"></i>
    </h3>
    <div class="row grid-1">
    
      <div class="col form-group d-flex align-items-center">
        <label class="form-label" for="bskills">Skills</label>
        <div class="form-field form-badges d-flex align-items-center flex-wrap">
          <template v-for="skill in skills" :key="`selected-skill-${skill.Id}`">
            
            <div class="badges" v-if="profileDetails.skills.includes(skill.Id)">
              {{ skill.SkillName }}
              <span class="badge-cls" @click.prevent="removeSkill(skill.Id)"
                >×</span
              >
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="row grid-2">
      <div class="col form-group d-flex align-items-center">
        <label class="form-label" for="skills">Choose Skills</label>
        <div class="form-field">
          <select class="select-box form-control" @change="selectSkill($event)">
            <option value="">Choose Skills</option>
            <template v-for="skill in skills" :key="skill.Id">
              <option
                :value="skill.Id"
                v-if="!profileDetails.skills.includes(skill.Id)"
              >
                {{ skill.SkillName }}
              </option>
            </template>
          </select>
        </div>
      </div>
      <div class="col form-group d-flex align-items-center">
        <label class="form-label" for="title">Title<!--span>*</span--></label>
        <div class="form-field">
          <input
            class="form-control"
            type="text"
            id="title"
            v-model.trim="profileDetails.title"
          />
          <!--span
            v-if="
              profileDetails.submitted &&
              v$.profileDetails.title.required.$invalid
            "
            class="error"
            >* required.</span
          >
          <span v-if="v$.profileDetails.title.minLength.$invalid" class="error"
            >Title should have at least
            {{ v$.profileDetails.title.minLength.$params.min }}.</span
          >
          <span v-if="v$.profileDetails.title.maxLength.$invalid" class="error"
            >Title should have at most
            {{ v$.profileDetails.title.maxLength.$params.max }}.</span
          -->
        </div>
      </div>
    </div>
    <div class="row grid-2">
      <div class="col form-group d-flex align-items-center">
        <label class="form-label" for="location">Location</label>
        <div class="form-field">
          <Select2
            id="profile-cities"
            v-model.trim="profileDetails.city"
            class="select2-box"
            :options="cities"
            :placeholder="`City`"
            :resetOptions="false"
            :allowClear="true"
          />
          <!--span
            v-if="
              profileDetails.submitted &&
              v$.profileDetails.city.required.$invalid
            "
            class="error"
            >* required.</span
          -->
        </div>
      </div>
      <div class="col form-group d-flex align-items-center">
        <label class="form-label" for="category">Category</label>
        <div class="form-field">
          <select
            v-model.trim="profileDetails.category"
            class="form-control select-box"
            @change="
              (event) => $emit('profileChangeCategory', event.target.value)
            "
          >
            <option value="">Category</option>
            <option
              v-for="category in categories"
              :key="category.id"
              :value="category.id"
            >
              {{ category.text }}
            </option>
          </select>
          <!--span
            v-if="
              profileDetails.submitted &&
              v$.profileDetails.category.required.$invalid
            "
            class="error"
            >* required.</span
          -->
        </div>
      </div>
    </div>
    <div class="row grid-2">
      <div class="col form-group d-flex align-items-center">
        <label class="form-label" for="subCategory">Sub Category</label>
        <div class="form-field">
          <select
            v-model.trim="profileDetails.subCategory"
            class="form-control select-box"
            @change="
              (event) => $emit('profileChangeSubCategory', event.target.value)
            "
          >
            <option value="">Sub Category</option>
            <option
              v-for="subcategory in subCategories"
              :key="subcategory.Id"
              :value="subcategory.Id"
            >
              {{ subcategory.CategoryName }}
            </option>
          </select>
          <!--span
            v-if="
              profileDetails.submitted &&
              v$.profileDetails.subCategory.required.$invalid
            "
            class="error"
            >* required.</span
          -->
        </div>
      </div>
      <!--div class="col form-group d-flex align-items-center">
        <label class="form-label" for="expertise">Expertise</label>
        <div class="form-field">
          <select
            v-model.trim="profileDetails.expertise"
            class="form-control select-box"
          >
            <option value="">Expertise</option>
            <option
              v-for="expertise in experties"
              :key="expertise.Id"
              :value="expertise.Id"
            >
              {{ expertise.ExpertiseName }}
            </option>
          </select>
          <span
            v-if="
              profileDetails.submitted &&
              v$.profileDetails.expertise.required.$invalid
            "
            class="error"
            >* required.</span
          >
        </div>
      </div-->
    </div>
    <div class="row grid-2">
      <div class="col form-group d-flex align-items-center">
        <label class="form-label" for="expertiseLevel">Expertise Level</label>
        <div class="form-field">
          <select
            v-model.trim="profileDetails.expertLevel"
            class="form-control select-box"
          >
            <option value="">Expertise Level</option>
            <option
              v-for="expertLevel in expertLevels"
              :key="expertLevel.id"
              :value="expertLevel.id"
            >
              {{ expertLevel.text }}
            </option>
          </select>
          <!--span
            v-if="
              profileDetails.submitted &&
              v$.profileDetails.expertLevel.required.$invalid
            "
            class="error"
            >* required.</span
          -->
        </div>
      </div>
      <div class="col form-group d-flex align-items-center">
        <label class="form-label" for="rate">Hourly Rate<!--span>*</span--></label>
        <div class="form-field">
          <div class="hourly-rate-field">
            <span class="field-icon">$</span>
            <input
              v-model.trim="profileDetails.hourlyPrice"
              class="form-control"
              placeholder="Hourly Rate"
              type="text"
              v-on:keypress="HourlyRate($event)"
              onpaste="return false;"
            />
            <!--span
              v-if="
                profileDetails.submitted &&
                v$.profileDetails.hourlyPrice.required.$invalid
              "
              class="error"
              >* required.</span
            >
            <span
              v-if="v$.profileDetails.hourlyPrice.minValue.$invalid"
              class="error"
              >Hourly Rate should be greater than 0.</span
            >
            <span
              v-if="v$.profileDetails.hourlyPrice.maxValue.$invalid"
              class="error"
              >Hourly Rate can be max 14 characters long.</span
            -->
          </div>
        </div>
      </div>
    </div>
    <div class="row grid-1">
      <div class="col form-group d-flex align-items-center">
        <label class="form-label" for="proLevel">Overview</label>
        <div class="form-field">
          <textarea
            v-model.trim="profileDetails.description"
            class="form-control"
            placeholder="Overview"
          ></textarea>
          <!--span
            v-if="
              profileDetails.submitted &&
              v$.profileDetails.description.required.$invalid
            "
            class="error"
            >* required.</span
          >
          <span
            v-if="v$.profileDetails.description.minLength.$invalid"
            class="error"
            >Description should have at least
            {{ v$.profileDetails.description.minLength.$params.min }}
            characters.</span
          >
          <span
            v-if="v$.profileDetails.description.maxLength.$invalid"
            class="error"
            >Description should have at most
            {{ v$.profileDetails.description.maxLength.$params.max }}
            characters.</span
          -->
        </div>
      </div>
    </div>
    <div class="row grid-1">
      <div class="col form-group d-flex align-items-center">
        <label class="form-label" for="proLevel">Address</label>
        <div class="form-field">
          <input
            v-model.trim="profileDetails.address"
            class="form-control"
            placeholder="Street Address"
            type="text"
          />
          <!--span
            v-if="
              profileDetails.submitted &&
              v$.profileDetails.address.required.$invalid
            "
            class="error"
            >* required.</span
          >
          <span
            v-if="v$.profileDetails.address.minLength.$invalid"
            class="error"
            >Address should have at least
            {{ v$.profileDetails.address.minLength.$params.min }}
            characters.</span
          >
          <span
            v-if="v$.profileDetails.address.maxLength.$invalid"
            class="error"
            >Address should have at most
            {{ v$.profileDetails.address.maxLength.$params.max }}
            characters.</span
          -->
        </div>
      </div>
    </div>
    <div class="row grid-2">
      <div class="col form-group d-flex align-items-center">
        <label class="form-label" for="proLevel">Postal Code</label>
        <div class="form-field">
          <input
            v-model.trim="profileDetails.postalCode"
            class="form-control"
            placeholder="Zip/ Postal Code"
            type="text"
          />
          <!--span
            v-if="
              profileDetails.submitted &&
              v$.profileDetails.postalCode.required.$invalid
            "
            class="error"
            >* required.</span
          >
          <span
            v-if="v$.profileDetails.postalCode.minLength.$invalid"
            class="error"
            >Postal code should have at least
            {{ v$.profileDetails.postalCode.minLength.$params.min }}
            characters.</span
          >
          <span
            v-if="v$.profileDetails.postalCode.maxLength.$invalid"
            class="error"
            >Postal code should have at most
            {{ v$.profileDetails.postalCode.maxLength.$params.max }}
            characters.</span
          -->
        </div>
      </div>
      <div class="col form-group d-flex align-items-center">
        <label class="form-label" for="proLevel">Phone Number</label>
        <div class="form-field">
          <input
            v-model.trim="profileDetails.phoneNumber"
            class="form-control"
            placeholder="Phone Number"
            type="text"
            v-on:keypress="PhoneNumber"
            onpaste="return false;"
          />
          <!--span
            v-if="
              profileDetails.submitted &&
              v$.profileDetails.phoneNumber.required.$invalid
            "
            class="error"
            >* required.</span
          >
          <span
            v-if="v$.profileDetails.phoneNumber.minLength.$invalid"
            class="error"
            >Phone Number should have at least
            {{ v$.profileDetails.phoneNumber.minLength.$params.min }}
            characters.</span
          >
          <span
            v-if="v$.profileDetails.phoneNumber.maxLength.$invalid"
            class="error"
            >Phone Number should have at least
            {{ v$.profileDetails.phoneNumber.maxLength.$params.max }}
            characters.</span
          -->
        </div>
      </div>
    </div>

    <div
      class="row grid-2 step-moreLang"
      v-for="(v, index) in v$.profileDetails.$model.languages"
      :key="index"
    >
      <div class="col form-group d-flex align-items-center">
        <label class="form-label" for="language">Languages</label>
        <div class="form-field">
          <select v-model.trim="v.language" class="form-control select-box">
            <option value="">Select Languages</option>
            <template v-for="lang in languages" :key="lang.id">
              <option
                :value="lang.id"
                v-if="
                  !profileDetails.languages.some(
                    (x) => x.language === lang.id
                  ) || lang.id === v.language
                "
              >
                {{ lang.text }}
              </option>
            </template>
          </select>
          <!--template
            v-if="
              v$.profileDetails.languages.$each.$response.$data[index].language
                .$invalid
            "
          >
            <span
              v-if="
                profileDetails.submitted &&
                !v$.profileDetails.languages.$each.$response.$data[index]
                  .language.required
              "
              class="error"
              >* required.</span
            >
          </template-->
        </div>
      </div>
      <div class="col form-group d-flex align-items-center">
        <label class="form-label" for="proLevel">Proficiecny Level</label>
        <div class="form-field">
          <select
            v-model.trim="v.proficiencyLevel"
            class="form-control select-box"
          >
            <option value="">Proficiency Level</option>
            <option
              v-for="langProf in languageProficiencies"
              :key="langProf.id"
              :value="langProf.id"
            >
              {{ langProf.text }}
            </option>
          </select>
          <!--template
            v-if="
              v$.profileDetails.languages.$each.$response.$data[index]
                .proficiencyLevel.$invalid
            "
          >
            <span
              v-if="
                profileDetails.submitted &&
                !v$.profileDetails.languages.$each.$response.$data[index]
                  .proficiencyLevel.required
              "
              class="error"
              >* required.</span
            >
          </template-->
        </div>
      </div>
      <span class="field-close" v-if="index > 0">
        <span
          class="lnr lnr-cross-circle"
          @click="profileDetails.languages.splice(index, 1)"
        ></span>
      </span>
    </div>
    <div
      class="add-action--btn"
      v-if="languages.length > profileDetails.languages.length"
    >
      <span
        class="add-more-btn"
        @click.prevent="
          profileDetails.languages.push({ language: '', proficiencyLevel: '' })
        "
        >+ Add More Languages</span
      >
    </div>

    <div class="row grid-2">
      <div class="col form-group d-flex align-items-center">
        <label class="form-label" for="proLevel">Image Upload</label>
        <div class="form-field">
          <div class="preview-avatar" v-if="profileDetails.avatar">
            <button
              class="field-close"
              @click.prevent="
                (profileDetails.avatar = ''), (profileDetails.photo = null)
              "
            >
              <span class="lnr lnr-cross-circle"></span>
            </button>
            <img :src="profileDetails.avatar" />
          </div>
          <div class="upload-file-field" v-else>
            <input
              class="form-control"
              type="file"
              accept=".jpg, .jpeg, .png"
              @change="uploadPhoto($event)"
            />
            <label class="placeholder" v-if="!profileDetails.photo">
              <template v-if="profileDetails.avatar">{{
                profileDetails.avatar.split("/").at(-1)
              }}</template>
              <template v-else>Profile Photo</template>
            </label>
            <span class="field-icon"
              ><span class="lnr lnr-cloud-upload"></span>Upload</span
            >
          </div>
          <!-- <span v-if="profileDetails.submitted && v$.profileDetails.photo.required.$invalid" class="error">Please select an Image.</span> -->
          <span v-if="v$.profileDetails.photo.imageSize.$invalid" class="error"
            >Image size should be less than 7mb.</span
          >
        </div>
      </div>
      <div class="col form-group d-flex align-items-center">
          <label class="form-label">Created On Upwork </label>
          <div class="form-field">
            <div class="date-field">
              <datepicker
                v-model="profileDetails.createdOnUpworks"
                :maxDate="new Date()"
                :enableTimePicker="false"
                autoApply
                placeholder="Created On Upwork"
                :format="format"
              >
                <template #input-icon>
                  <span class="lnr lnr-calendar-full"></span>
                </template>
              </datepicker>
              <!--template
                v-if="
                  v.submitted &&
                  v$.workDetails.$each.$response.$data[index].fromDate.$invalid
                "
              >
                <span
                  v-if="
                    !v$.workDetails.$each.$response.$data[index].fromDate
                      .required
                  "
                  class="error"
                  >* required.</span
                >
              </template-->
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import Datepicker from '@vuepic/vue-datepicker';
import NumbersOnly from "../../mixins/numbersOnly";
import useVuelidate from "@vuelidate/core";
//import {helpers,required,minLength,maxLength,minValue,maxValue,} from "@vuelidate/validators";
import Select2 from "../Select2.vue";

const validateImage = (file) => {
  const maxFileSize = 7000000;
  if (file === "" || file == null) return true;

  if (file.size <= maxFileSize) {
    return true;
  }
  return false;
};

export default {
  setup() {
    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day < 10 ? "0" + day : day}/${month < 10 ? "0" + month : month}/${year}`;
    };

    return {
      format,
      v$: useVuelidate(),
    };
  },
  components: { Select2 , Datepicker },
  props: {
    fields: {
      type: Object,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    subCategories: {
      type: Array,
      required: true,
    },
    experties: {
      type: Array,
      required: true,
    },
    expertLevels: {
      type: Array,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    languageProficiencies: {
      type: Array,
      required: true,
    },
    cities: {
      type: Array,
      required: true,
    },
    skills: {
      type: Array,
      required: true,
    },
  },
  computed: {
    profileDetails: {
      get() {
        console.log('testing 11',this.fields);
        return this.fields;
      },
      set(value) {
        this.$emit("update:profileDetails", value);
      },
    },
  },
  mixins: [NumbersOnly],
  validations() {
    return {
      profileDetails: {
        /*category: {
          required,
        },
        subCategory: {
          required,
        },
        expertise: {
          required,
        },
        expertLevel: {
          required,
        },
        hourlyPrice: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(99999999999999.99),
        },
        title: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(150),
        },
        description: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(5001),
        },
        city: {
          required,
        },
        postalCode: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(10),
        },
        address: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(150),
        },
        phoneNumber: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(20),
        },
        languages: {
          $each: helpers.forEach({
            language: {
              required,
            },
            proficiencyLevel: {
              required,
            },
          }),
        },*/
        photo: {
          // required,
          imageSize: validateImage,
        },
      },
    };
  },
  methods: {
    uploadPhoto(e) {
      let vm = this;
      /**
       * Reads image of file input, and parses it as URL.
       */
      if (e.target.files && e.target.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          vm.profileDetails.avatar = e.target.result;
        }.bind(vm);

        reader.readAsDataURL(e.target.files[0]);

        vm.profileDetails.photo = e.target.files[0];
      }
    },
    selectSkill(e) {
      let vm = this;
      let skillId = e.target.value;

      if (!vm.profileDetails.skills.includes(skillId)) {
        vm.profileDetails.skills.push(skillId);
      }
    },
    removeSkill(skillId) {
      let vm = this;

      if (vm.profileDetails.skills.includes(skillId)) {
        const index = vm.profileDetails.skills.indexOf(skillId);
        vm.profileDetails.skills.splice(index, 1);
      }
    },
  },
};
</script>
